import React from 'react';
import '../styles/Hero3.css';
import { Link } from 'react-scroll';



const Hero3_1 = () => {
    return (
        <section className="hero3_1" id='section3_1'>
            <div className="hero3_1-content">
                <h1>Unser Versprechen an euch</h1>
                <p>
                    Wir, zwei Jungunternehmer aus Österreich, haben uns mit DOCH! das Ziel gesetzt, die Aromen des
                    Marchfelds in einer einzigartigen Würzmischung für eure Küche festzuhalten.
                </p>
                <p>
                    In enger
                    Zusammenarbeit mit regionalen Landwirten wählen wir das beste Gemüse aus und verarbeiten es
                    schonend – ohne künstliche Zusätze, nur mit der Kraft der Natur. Mit DOCH!
                    nutzen wir auch Gemüse, das sonst oft übersehen wird, und verwandeln es in etwas Großartiges.
                </p>
                <p>
                    Unser DOCH! allrounder steht für echten Geschmack, Regionalität und Nachhaltigkeit. Mit jedem
                    Löffel bringt ihr nicht nur Würze in euer Gericht, sondern auch ein Stück Heimat und Liebe zur
                    Natur auf den Teller.
                </p>

            </div>


        </section>
    );
};

export default Hero3_1;
