import React from "react";
import '../styles/Footer.css'
import logo from '../assets/logo.png';
import logo2 from '../assets/logo2.png';
import { FaPhone, FaEnvelope } from 'react-icons/fa'; // Импорт иконок из react-icons



const Footer = () => {
    return (
        <footer>
            <div className="footerLeft">
                <div className="contactItem">
                    <FaPhone className="icon" />
                    <a href="tel:+436649250881" className="contactLink">+43 664 9250881</a>
                </div>
                <div className="contactItem">
                    <FaEnvelope className="icon" />
                    <a href="mailto:doch@dochwuerzt.com" className="contactLink">doch@dochwuerzt.com</a>
                </div>
            </div>

            <div className="footerMiddle">
                <img className="footerLogo2" src={logo2} alt="logo2" />
                <img className="footerLogo" src={logo} alt="logo" />
                <p><b>get ready for the flavour revolution</b>
                </p>
                {/* <p>&copy; {new Date().getFullYear()} Z-Works. </p> */}
            </div>

            <div className="footerRight">
                <p>#findyourflavour</p>

            </div>


        </footer>
    );
};

export default Footer;