// src/components/Hero2.js
import React from 'react';
import '../styles/Hero2.css';
import { Link } from 'react-scroll';


const Hero2 = () => {
    return (
        <section className="hero2" id='section1'>
            <div className="hero2-content">
                <h1><b style={{ fontWeight: '800' }}>DOCH!</b> <b style={{ fontWeight: '300' }}>würzt</b></h1>
                <p><b>Frisches Gemüse</b> wie Karotten, Sellerie, Gelbe Rüben, Pastinaken, Porree (Lauch), Petersilie,
                    Zwiebel und Knoblauch werden direkt bei <b>lokalen Bauernhöfen</b> aus dem Marchfeld gekauft und in
                    einem von uns speziell entwickelten Verfahren schonend getrocknet. So bleiben die <b>Aromen und
                    Nährstoffe</b> erhalten. Wir verwenden bewusst auch Gemüse, das zu „hässlich“ für den
                    Supermarkt ist, aber geschmacklich voll überzeugt. Anschließend mahlen wir das Gemüse zu feinem Pulver, bevor es zu
                    einer <b>geheimen Mischung</b> abgestimmt wird.
                    <br />
                    <br />
                    Das Ergebnis? Eine natürliche Würze, die mit ihrem intensiven Geschmack begeistert, ohne
                    Zusatzstoffe auskommt und die Essenz des Marchfelds auf den Teller bringt.
                    <br />
                    <br />
                    Kurz gesagt: Mit jedem Löffel „MarchfeldWürze“ genießt du den <b>Geschmack Österreichs</b>.</p>
            </div>
            {/* <div className="down-button">
                <Link
                    to="section2_1"
                    smooth={true}
                    duration={500}
                    className=""
                >
                    <svg className="arrow" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                        <path d="M12 16.5l-8-8h16z" />
                    </svg>
                </Link>

            </div>
            <div className="up-button">
                <Link
                    to="hero"
                    smooth={true}
                    duration={500}
                    className="up-button-link"
                // offset={-60} // Отступ для учета фиксированного Navbar (например, 60px)
                >
                    <svg className="arrow-up" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                        <path d="M12 7.5l8 8H4z" />
                    </svg>
                </Link>
            </div> */}
        </section>
    );
};

export default Hero2;
