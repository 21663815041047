// Navbar.js
import React, { useState } from 'react';
import '../styles/Navbar.css';
import logo from '../assets/logo.png';
import { Link } from 'react-scroll';
import { useNavigate, useLocation } from 'react-router-dom';


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      {/* <Link
          to="hero1"
          smooth={true}
          duration={500}
          className=""
          onClick={() => setIsOpen(false)}
          spy={true}
          offset={-70} // При необходимости добавьте смещение
          activeClass="active" // Класс для активной ссылки
        >
      <img className="navbar-logo" src={logo} alt="logo" />
      </Link> */}

      {location.pathname === '/' ? (

        <Link to="hero" smooth={true} duration={500} className="">
          <img className="navbar-logo" src={logo} alt="logo" />

        </Link>

      ) : (

        <p className="navLink" href="" onClick={() => {
          navigate('/');
          setIsOpen(false);
        }}>
          <img className="navbar-logo" src={logo} alt="logo" />
        </p>
      )}



      <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
        {/* <Link
          to="hero1"
          smooth={true}
          duration={500}
          className="navLink first"
          onClick={() => setIsOpen(false)}
          spy={true}
          offset={-70} // При необходимости добавьте смещение
          activeClass="active" // Класс для активной ссылки
        >
          Home
        </Link> */}
        {/* <Link
          to="hero2"
          smooth={true}
          duration={500}
          className="navLink"
          onClick={() => setIsOpen(false)}
          spy={true}
          offset={-70}
          activeClass="active"
        >
          DOCH! würzt alles
        </Link> */}
        {/* <Link
          to="hero2_2"
          smooth={true}
          duration={500}
          className="navLink"
          onClick={() => setIsOpen(false)}
          spy={true}
          offset={-70}
          activeClass="active"
        >
          Unsere Geschichte
        </Link> */}

        {location.pathname === '/' ? (

          <Link to="hero" smooth={true} duration={500} className="navLink first">
            Home
          </Link>

        ) : (

          <p className="navLink first" href="" onClick={() => {
            navigate('/');
            setIsOpen(false);
          }}>
            Home
          </p>
        )}






        {/* <a className="navLink" href="#hero2" onClick={() => setIsOpen(false)}>
              DOCH! würzt alles
            </a>
            <a className="navLink" href="#hero2_2" onClick={() => setIsOpen(false)}>
              Unsere Geschichte
            </a> */}

        <p className="navLink" href="" onClick={() => {
          navigate('/impressum');
          setIsOpen(false);
        }}>
          Impressum
        </p>




        <p className="navLink" href="" onClick={() => {
          navigate('/AGB');
          setIsOpen(false);
        }}>
          AGB
        </p>

        <p className="navLink" href="" onClick={() => {
          navigate('/Datenschutz');
          setIsOpen(false);
        }}>
          Datenschutz
        </p>


      </div>
      <div className={`burger-menu ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>
    </nav>
  );
};

export default Navbar;
