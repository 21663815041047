import React, { useState, useEffect, useRef } from 'react';
import '../styles/Hero3.css';
import photo1 from '../assets/1.jpg';
import photo2 from '../assets/2.jpg';
import photo3 from '../assets/3.jpg';
import photo4 from '../assets/4.jpg';

const Hero3 = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Определяем, мобильный ли экран
    const scrollRef = useRef(null);

    const handleWheel = (event) => {
        event.preventDefault();
        if (scrollRef.current) {
            scrollRef.current.scrollLeft += event.deltaY;
        }
    };

    // Отслеживаем изменение ширины экрана
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Считаем экран мобильным, если ширина ≤ 768px
        };

        window.addEventListener('resize', handleResize); // Добавляем слушатель
        return () => window.removeEventListener('resize', handleResize); // Убираем слушатель
    }, []);

    return (
        <section className="hero3" id="section3">
            <div className="hero3-content">
                <h1>Unsere Idee zu DOCH!</h1>
                <p>
                    Alles begann in der eigenen Küche, mit der Frage: Warum gibt es keine Würzmischung, die
                    wirklich gesund ist – ohne Zusätze, ohne Kompromisse? Als leidenschaftliche Köche
                    suchten wir nach einer Möglichkeit, Gerichte nicht nur
                    lecker, sondern auch natürlich und bewusst zu würzen.
                </p>
                <p>
                    Die Inspiration für DOCH! kam schließlich bei einer gemeinsamen Fahrradtour durchs
                    Marchfeld. Umgeben von der Vielfalt und Frische der regionalen Gemüsesorten erkannten
                    wir das Potenzial, diese hochwertigen Zutaten in einer neuen, haltbaren Form in die Küchen
                    der Stadt zu bringen.
                </p>
                <p>
                    Nach unzähligen Experimenten entstand DOCH! – eine Linie von Würzmischungen, die
                    natürliche Zutaten, puren Geschmack und nachhaltiges Kochen vereint. Jede Prise DOCH!
                    trägt die Philosophie in sich, dass bewusstes und gesundes Würzen einfach, lecker und
                    unkompliziert sein kann.
                </p>
                <p>
                    Mit DOCH! flavours wollen wir nicht nur den Geschmack revolutionieren, sondern auch Menschen
                    dazu inspirieren, nachhaltiger und bewusster zu kochen – ohne dabei auf Genuss zu verzichten.
                </p>
            </div>

            {isMobile ? (
               
                 <div
                 className="scroll-container2"
                 ref={scrollRef}
                 onWheel={handleWheel}
             >
                 <div className="scroll-item2">
                     <img src={photo1} alt="Beschreibung Foto 1" />
                 </div>
                 <div className="scroll-item2">
                     <img src={photo2} alt="Beschreibung Foto 2" />
                 </div>
                 <div className="scroll-item2">
                     <img src={photo3} alt="Beschreibung Foto 3" />
                 </div>
                 <div className="scroll-item2">
                     <img src={photo4} alt="Beschreibung Foto 4" />
                 </div>
             </div>
            ) : (
                <div className="gallery">
                    <img  src={photo1} alt="Beschreibung Foto 1" />
                    <img  src={photo2} alt="Beschreibung Foto 2" />
                    <img  src={photo3} alt="Beschreibung Foto 3" />
                    <img  src={photo4} alt="Beschreibung Foto 4" />
                </div>
            )}
        </section>
    );
};

export default Hero3;
