import React from 'react';
import '../styles/Datenschutz.css';
import { Link } from 'react-scroll';




const Datenschutz = () => {
    return (
        <section className="Datenschutz" id='Datenschutz'>
            <div className="Datenschutz-content">

                <h1>Datenschutzerklärung</h1>

                <h3>Allgemeines</h3>

                <p>
                    Datenschutz ist Vertrauenssache und Ihr Vertrauen ist uns wichtig. Wir respektieren Ihre Privat- und Persönlichkeitssphäre. Die Sicherheit und die vertrauliche Behandlung Ihrer Daten ist unser oberstes Prinzip. Damit Sie sich bei dem Besuch unserer Webseiten sicher fühlen, beachten wir bei der Verarbeitung Ihrer personenbezogenen Daten streng die gesetzlichen Bestimmungen und möchten Sie hier über unsere Datenerhebung und Datenverwendung informieren. Mit der Zustimmung zu der nachfolgenden Datenschutzerklärung willigen Sie gegenüber der Christoph Salomon GmbH, Spöttlgasse 7/3/13, 1210 Wien in die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten unter Beachtung der Datenschutzgesetze und den nachfolgenden Bestimmungen ein.
                </p>

                <h3>Erhebung, Nutzung und Verarbeitung personenbezogener Daten</h3>

                <p>
                    Indem Sie unsere Website besuchen, werden auf unseren Servern bestimmte Daten automatisch zu Zwecken der Systemadministration, für statistische oder für Sicherungszwecke gespeichert.
                </p>
                <p>
                    Es handelt sich dabei um den Namen Ihres Internetserviceproviders, u.U. Ihre IP-Adresse, die Version Ihrer Browser-Software, das Betriebssystem des Rechners, mit dem zugegriffen wurde, die Website, von der aus Sie uns besuchen, die Webseiten, die Sie bei uns besuchen und ggf. Suchwörter, die Sie benutzt haben, um unsere Website zu finden.
                </p>
                <p>
                    Diese Daten können unter Umständen Rückschlüsse über einen bestimmten Besucher der Website zulassen. Personenbezogene Daten werden in diesem Zusammenhang jedoch nicht verwertet. Die Nutzung solcher Daten erfolgt ausschließlich anonym.
                </p>

                <h3>Einbindung von Diensten und Inhalten Dritter</h3>

                <p>
                    Es kann vorkommen, dass innerhalb des Onlineangebotes von der Christoph Salomon GmbH Inhalte Dritter, wie zum Beispiel Videos von YouTube, Kartenmaterial von Google Maps oder Grafiken von anderen Webseiten eingebunden werden.
                </p>
                <p>
                    Dies setzt immer voraus, dass die Anbieter dieser Inhalte die IP-Adresse der Nutzer wahrnehmen, denn ohne die IP-Adresse können sie die Inhalte nicht an den Browser des jeweiligen Nutzers senden. Die IP-Adresse ist damit für die Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Jedoch haben wir keinen Einfluss darauf, falls die Dritt-Anbieter die IP-Adresse zum Beispiel für statistische Zwecke speichern. Soweit uns dies bekannt ist, klären wir die Nutzer darüber auf.
                </p>

                <h3>Cookies</h3>

                <p>
                    Cookies sind kleine Datendateien auf Ihrem Computer, die es ermöglichen, auf dem Zugriffsgerät der Nutzer (PC, Tablet, Smartphone o.ä.) spezifische, auf das Gerät bezogene Informationen zu speichern. Sie dienen zum einem der Benutzerfreundlichkeit von Webseiten und damit den Nutzern (z.B. Speicherung von Logindaten). Zum anderen dienen sie dazu, statistische Daten der Webseitennutzung zu erfassen und diese zwecks Verbesserung des Angebotes analysieren zu können. Die Nutzer können auf den Einsatz der Cookies Einfluss nehmen. In den gängigen Browsern können Sie Cookies löschen und dafür sorgen, dass sie zukünftig nicht mehr akzeptiert werden. Beachten Sie jedoch, dass damit einige Funktionen unserer Website nur eingeschränkt nutzbar sind.
                </p>

                <h3>Webanalyse mit Google Analytics</h3>

                <p>
                    Dieses Angebot benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf den Computern der Nutzer gespeichert werden und die eine Analyse der Benutzung der Website ermöglichen. Die durch Cookies erzeugten Informationen über die Benutzung der Website durch die Nutzer werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Jedoch wird im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite die IP-Adresse bei Nutzern von Google innerhalb der Mitgliedstaaten der Europäischen Union oder bei Nutzern aus anderen Vertragsstaaten des Europäischen Wirtschaftsraums zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die IP-Anonymisierung ist auf dieser Website aktiv. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um die Nutzung der Website durch die Nutzer auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Die Nutzer können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; Dieses Angebot weist die Nutzer jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Die Nutzer können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) durch Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: 
                    <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target='blank'> http://tools.google.com/dlpage/gaoptout?hl=de</a>.
                </p>

                <h3>Kontaktaufnahme und Auskunft</h3>

                <p>
                    Bei der Kontaktaufnahme mit der Christoph Salomon GmbH (per Kontaktformular oder E-Mail) werden die Nutzerangaben zwecks Bearbeitung der Anfrage sowie für den Fall, dass Anschlussfragen entstehen, gespeichert. Nach dem Datenschutzgesetz aus dem Jahre 2000 (DSG 2000) haben Sie jederzeit das Recht, Auskunft über die zu Ihrer Person gespeicherten Daten und den Zweck der Speicherung dieser Daten zu erhalten.
                </p>

            </div>

        </section>
    );
};

export default Datenschutz;
