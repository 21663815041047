import React from 'react';
import '../styles/AGB.css';
import { Link } from 'react-scroll';




const AGB = () => {
    return (
        <section className="AGB" id='AGB'>
            <div className="AGB-content">


                <h1>Allgemeine Geschäfts- & Lieferbedingungen</h1>
                <p>
                    Allgemeine Geschäfts- und Lieferbedingungen der Firma Christoph Salomon GmbH, Produktion von und Handel mit Gewürzen, Spöttlgasse 7/3/13, 1210 Wien, UID-Nummer: ATU80300425, Firmenbuchnummer: FN 619067 v Handelsgericht Wien, Geschäftsführer Christoph Salomon.
                </p>
                <h3>
                    I. Allgemeines, Geltungsbereich
                </h3>
                <ol>
                    <li>
                        Alle Lieferungen- Leistungen und Angebote von Christoph Salomon GmbH erfolgen ausschließlich aufgrund dieser Allgemeinen Geschäfts- und Lieferbedingungen. Diese sind Bestandteil aller Verträge, die Christoph Salomon GmbH mit seinen Vertragspartnern (nachfolgend auch „Auftraggeber“ genannt) über die von ihm angebotenen Lieferungen oder Leistungen schließt. Sie gelten auch für alle zukünftigen Lieferungen, Leistungen oder Angebote an den Auftraggeber, selbst wenn sie nicht nochmals gesondert vereinbart werden. Mit Auftragserteilung hat der Auftraggeber diese Bedingungen zur Kenntnis genommen und akzeptiert.
                    </li>
                    <li>
                        Geschäftsbedingungen des Vertragspartners sind für gegenständliches Rechtsgeschäft unwirksam und wiederspricht der Christoph Salomon GmbH bereits jetzt der Geltung fremder AGBs. Diese finden nur Geltung, sofern sie durch die Christoph Salomon GmbH ausdrücklich und schriftlich anerkannt bzw. genehmigt wurden. Die Christoph Salomon GmbH schließt grundsätzlich nur zu seinen Bedingungen ab und stimmt der Vertragspartner der Geltung der Bedingungen von der Christoph Salomon GmbH automatisch zu, sofern kein Widerruf binnen 7 Tagen ab Auftragserteilung erfolgt.
                    </li>
                </ol>


                <h3>
                    II. Angebot und Vertragsabschluss
                </h3>
                <ol>
                    <li>
                        Aufträge werden grundsätzlich in Schriftform angenommen. Die Annahme (oder Ablehnung) erfolgt ebenfalls schriftlich. Änderungen werden auch nur in Schriftform akzeptiert.
                    </li>
                    <li>
                        Für die Einhaltung der Schriftform ist eine Versendung per Email ausreichend.
                    </li>
                    <li>
                        Alle Angebote von der Christoph Salomon GmbH und Kostenvoranschläge bzw. die Auftragsschreiben der Auftraggeber sind bis zur schriftlichen Auftragsbestätigung durch die Christoph Salomon GmbH freibleibend und unverbindlich.
                    </li>
                    <li>
                        Bei Abschluss eines mündlichen oder fernmündlichen Kaufvertrages ist der Inhalt des schriftlichen Bestätigungsschreibens maßgebend, sofern der Empfänger nicht unverzüglich widerspricht. Ergänzungen und Abänderungen der getroffenen Vereinbarung einschließlich dieser Geschäftsbedingungen bedürfen zu ihrer Wirksamkeit der Schriftform.
                    </li>
                    <li>
                        Angaben von der Christoph Salomon GmbH zum Gegenstand der Lieferung oder Leistung (z.B. Gewicht, Maße, Gebrauch) sowie dessen Darstellungen derselben (z.B. Zeichnungen und Abbildungen) sind nur annähernd maßgeblich, soweit nicht die Verwendbarkeit zum vertraglichen vorgesehenen Zweck eine genaue Übereinstimmung voraussetzt. Sie stellen keine garantierten Beschaffenheitsmerkmale dar, sondern beschreiben oder kennzeichnen die Lieferung oder Leistung. Handelsübliche Abweichungen sind zulässig, soweit sie die Verwendbarkeit zum vertraglich vorgesehenen Zweck nicht beeinträchtigen.
                    </li>
                    <li>
                        Durch die Unterschriftsleistung des Auftraggebers am Lieferschein wird der mündlich erteilte Auftrag ebenso bestätigt.
                    </li>
                </ol>


                <h3>
                    III. Preise und Zahlungen
                </h3>
                <ol>
                    <li>
                        Die Preise gelten für den in den Auftragsbestätigungen aufgeführten Leistungs- und Lieferumfang. Mehr- oder Sonderleistungen werden gesondert berechnet. Die Preise verstehen sich in EURO netto franco Lager zuzüglich der bei Lieferung geltenden gesetzlichen Umsatzsteuer.
                    </li>
                    <li>
                        Für den Fall, dass die Christoph Salomon GmbH selbst Auftraggeber ist und Ware einkauft, gelten die mit dem Auftragnehmer vereinbarten Preise ebenso netto franco Lager und sind darin auch alle anfallenden Nebenkosten wie Transport-, Fahrt-, Verpackungs- und Versicherungskosten, bei Importlieferungen Zoll sowie Gebühren und anderer öffentlicher Abgaben enthalten.
                    </li>
                    <li>
                        Die Einhaltung der vereinbarten Zahlungstermine bildet eine wesentliche Bedingung für die Durchführung der Lieferung und/oder Leistung durch die Christoph Salomon GmbH. Bei Nichteinhaltung der vereinbarten Zahlungsbedingungen ist die Christoph Salomon GmbH berechtigt, jede Tätigkeit und Lieferung einzustellen und vom Vertrag zurück zu treten. Weitgehende Ansprüche von der Christoph Salomon GmbH auf vollständige Leistung und Bezahlung sowie Schadenersatz bleiben der Christoph Salomon vorbehalten.
                    </li>
                    <li>
                        Bei Aufträgen, die mehrere Einheiten umfassen, ist die Christoph Salomon GmbH berechtigt, nach Lieferung jeder einzelnen Einheit oder Leistung Rechnung zu legen.
                    </li>
                    <li>
                        Für Teilrechnungen gelten die für den Gesamtauftrag festgelegten Zahlungsbedingungen gleichermaßen.
                    </li>
                    <li>
                        Bei Nichteinhaltung der Zahlungsbedingungen steht der Christoph Salomon GmbH das Recht zu, den Auftrag nicht auszuführen, wobei das Entgelt für die Leistung, soweit sie erbracht wurde, sofort fällig ist.
                    </li>
                    <li>
                        Werden spätere Änderungen der Angebotsunterlagen erwünscht oder notwendig oder ändern sich Maß, Anzahl, Gewicht, Bearbeitung u.a., so bleibt die Christoph Salomon GmbH zu einer entsprechenden Änderung des Preises berechtigt.
                    </li>
                    <li>
                        Soweit den vereinbarten Preisen die (täglichen) Listenpreise von der Christoph Salomon GmbH zugrunde liegen und die Lieferung erst mehr als 1 Woche nach Vertragsabschluss erfolgen soll, gelten die bei Lieferung gültigen Listenpreise von der Christoph Salomon GmbH (jeweils abzüglich eines vereinbarten prozentualen oder festen Rabatts).
                    </li>
                    <li>
                        Der Kaufpreis ist grundsätzlich, falls nichts Anderes schriftlich vereinbart wurde, sofort nach Lieferung und Rechnungslegung ohne jeden Abzug zur Zahlung fällig. Verzug setzt spätestens nach 10 Tagen nach Rechnungslegung ein.
                    </li>
                    <li>
                        Verzugszinsen nach Eintritt des Verzuges werden Zinsen in Höhe von 8 Prozentpunkten über dem jeweiligen Basiszinssatz berechnet. Die Geltendmachung höherer Zinsen und weiterer Schäden im Falle des Verzuges bleibt unberührt. Darüber hinaus sind sämtliche Mahn- und Inkassogebühren bzw. Kosten anwaltlichen Einschreitens vom Auftraggeber zu tragen. Bei Verzugseintritt ist die Christoph Salomon GmbH darüber hinaus berechtigt, seine Leistungen zurück zu behalten bzw. vom Vertrag zurück zu treten. Dabei hat der Auftraggeber sämtliche der Christoph Salomon GmbH dadurch entstehenden Kosten (bisheriger Aufwand) sowie den Gewinnentgang zu ersetzen.
                    </li>
                    <li>
                        Die Aufrechnung mit Gegenansprüchen des Auftraggebers oder die Zurückbehaltung von Zahlungen wegen solcher Ansprüche sind nur zulässig, soweit die Gegenansprüche unbestritten oder rechtskräftig festgestellt sind.
                    </li>
                    <li>
                        Die Christoph Salomon GmbH ist berechtigt, noch ausstehende Lieferungen oder Leistungen nur gegen Vorauszahlung oder Sicherheitsleistung auszuführen oder zu erbringen, wenn ihm nach Abschluss des Vertrages Umstände bekannt werden, welche die Kreditwürdigkeit des Auftraggebers wesentlich zu mindern geeignet sind und durch welche die Bezahlung der offenen Forderung von der Christoph Salomon GmbH durch den Auftraggeber aus dem jeweiligen Vertragsverhältnis (einschließlich aus anderen Einzelaufträgen, für die derselbe Rahmenvertrag gilt) gefährdet wird.
                    </li>
                </ol>


                <h3>
                    IV. Leistungserbringung
                </h3>
                <ol>
                    <li>
                        Die Christoph Salomon GmbH ist bestrebt, die vereinbarten Termine der Erfüllung nach Maßgabe der Möglichkeiten genau einzuhalten.
                    </li>
                    <li>
                        Leistungen können nach Wahl von der Christoph Salomon GmbH, durch Mitarbeitet von der Christoph Salomon GmbH oder durch selbständige Dritte im Auftrag von der Christoph Salomon GmbH erbracht werden.
                    </li>
                    <li>
                        Wird die Ausführung des Auftrages nach Vertragsunterfertigung durch den Vertragspartner verhindert, so ist die Christoph Salomon GmbH berechtigt, auf Erfüllung zu bestehen oder Schadenersatz in der Höhe des gesamten Entgeltes und darüber hinaus zu begehren.
                    </li>
                    <li>
                        Unterbleibt die Ausführung des Auftrages durch Umstände, die auf Seiten von der Christoph Salomon GmbH einen wichtigen Grund darstellen, so hat die Christoph Salomon GmbH nur Anspruch auf den bisherigen Leistungen entsprechenden Teil des Entgeltes. Dies gilt insbesondere dann, wenn trotz Kündigung für den Vertragspartner die bisherigen Leistungen verwertbar sind.
                    </li>
                </ol>


                <h3>
                    V. Lieferung, Gefahrenübergang und Lieferzeit
                </h3>
                <ol>
                    <li>
                        Grundsätzlich geht die Gefahr auf den Auftraggeber über, sobald die bestellte Ware das Werk oder Lager verlassen hat oder dem Kunden zur Abholung im Werk oder Lager zur Verfügung gestellt wird, daher:
                    </li>
                    <li>
                        Lieferungen erfolgen ab Werk bzw. Lager.
                    </li>
                    <li>
                        Die Beförderung einschließlich des Verladens der Lieferung erfolgt auf Gefahr und Rechnung des Vertragspartners.
                    </li>
                    <li>
                        Der Gefahrenübergang erfolgt in jedem Fall beim Verlassen des Werkes oder Lagers von der Christoph Salomon GmbH.
                    </li>
                    <li>
                        Lieferung frei Haus oder Lager bedeuten ohne Abladung durch den Anlieferer.
                    </li>
                    <li>
                        Versandweg und –mittel sind mangels besonderer Vereinbarungen der Wahl der Christoph Salomon GmbH überlassen.
                    </li>
                    <li>
                        Lieferzeiten/-Termine sind stets unverbindlich, es sei denn wir haben sie ausdrücklich als verbindliche Liefertermine schriftlich bestätigt.
                    </li>
                    <li>
                        Termin- und Fristvereinbarungen stehen unter dem Vorbehalt der rechtzeitigen Selbstbelieferung durch unsere Lieferanten oder Kooperationspartner.
                    </li>
                    <li>
                        Die Einhaltung der Lieferzeit setzt voraus, dass der Auftrag vollständig geklärt ist, alle Genehmigungen erteilt sowie sämtliche vom Auftraggeber beizubringenden Unterlagen, Zahlungen und Sicherheitsleistungen termingemäß bei uns eingegangen sind. Die Lieferzeit ist eingehalten, wenn die Sendung innerhalb der vereinbarten Frist versandbereit und eine entsprechende Mitteilung an den Auftraggeber abgesandt ist.
                    </li>
                    <li>
                        Die Christoph Salomon GmbH kann- unbeschadet seiner Rechte aus Verzug des Auftraggebers- vom Auftraggeber eine Verlängerung von Liefer- und Leistungsfristen oder eine Verschiebung von Liefer- und Leistungsterminen um den Zeitraum verlangen, in dem der Auftraggeber seinen vertraglichen Verpflichtungen der Christoph Salomon GmbH gegenüber nicht nachkommt.
                    </li>
                    <li>
                        Die Christoph Salomon GmbH haftet nicht für Unmöglichkeit der Lieferung oder für Lieferverzögerungen, soweit diese durch höhere Gewalt oder sonstige, zum Zeitpunkt des Vertragsabschlusses nicht vorhersehbare Ereignisse (z.B. Betriebsstörungen aller Art, Maschinenbruch, Transportverzögerungen, Streiks, rechtmäßige Aussperrungen, Mangel an Arbeitskräften, Energie oder Rohstoffen, Schwierigkeiten bei der Beschaffung von notwendigen behördlichen Genehmigungen, behördliche Maßnahmen oder die ausbleibende, nicht richtige oder nicht rechtzeitige Belieferung durch Lieferanten) verursacht worden sind, die Christoph Salomon GmbH nicht zu vertreten hat. Sofern solche Ereignisse der Christoph Salomon GmbH die Lieferung oder Leistung wesentlich erschweren oder unmöglich machen und die Behinderung nicht nur von vorübergehender Dauer ist, ist die Christoph Salomon GmbH zum Rücktritt vom Vertrag berechtigt. Bei Hindernissen vorübergehender Art verlängern sich die Liefer- oder Leistungsfristen oder verschieben sich die Liefer- oder Leistungsfristen um den Zeitraum der Behinderung zuzüglich einer angemessenen Anlauffrist. Soweit dem Auftraggeber infolge der Verzögerung die Abnahme der Lieferung oder Leistung nicht zumutbar ist, kann er durch unverzügliche schriftliche Erklärung gegenüber der Christoph Salomon GmbH vom Vertrag zurücktreten.
                    </li>
                    <li>
                        Gerät die Christoph Salomon GmbH mit einer Lieferung oder Leistung in Verzug oder wird ihm eine Lieferung oder Leistung, gleich aus welchem Grunde, unmöglich, so ist die Haftung von der Christoph Salomon GmbH auf Schadenersatz nach Maßgabe des Punkt VII dieser allgemeinen Lieferbedingungen beschränkt.
                    </li>
                    <li>
                        Verzugspönale oder Schadenersatzansprüche für verspätete Lieferungen sind auf grobe Fahrlässigkeit bzw. Vorsatz beschränkt.
                    </li>
                    <li>
                        Die Sendung wird von der Christoph Salomon GmbH nur auf ausdrücklichen Wunsch des Auftraggebers und auf seine Kosten gegen Diebstahl, Bruch-, Transport-, Feuer- und Wasserschäden oder sonstige versicherbar Risiken versichert.
                    </li>
                </ol>


                <h3>
                    VI. Gewährleistung
                </h3>
                <ol>
                    <li>
                        Die Gewährleistungsfrist richtet sich nach dem Mindesthaltbarkeitsdatum der von der Christoph Salomon GmbH gelieferten Waren.
                    </li>
                    <li>
                        Lieferungen und Leistungen gleicher Art sind durch den Vertragspartner unverzüglich auf Richtigkeit und Beschaffenheit zu kontrollieren. Mängelrügen sind längstens binnen 3 Werktagen inklusiver angefertigtem Fotokonvolut und frachtfrei retournierter Ware schriftlich zu erstatten. Mängel können nach Wahl von der Christoph Salomon GmbH durch Nachbesserung oder Nachlieferung behoben werden. Ist die Beseitigung eines Mangels nach Lage der Dinge unmöglich oder würde sie einen unverhältnismäßigen hohen Aufwand verursachen, kann sie durch die Christoph Salomon GmbH verweigert werden. In diesem Fall, und wenn die Nachbesserung fehlschlägt, kann der Vertragspartner nur Preisminderung begehren, es sei denn, die Christoph Salomon GmbH stimmt einer Wandlung zu.
                    </li>
                    <li>
                        Die Gewährleistungsansprüche des Vertragspartners beschränken sich auf Verbesserung, Preisminderung sowie Nachtrag des Fehlenden.
                    </li>
                    <li>
                        Der Vertragspartner ist nicht berechtigt, die Abnahme, Lieferungen und Leistungen wegen nur geringfügiger Mängel abzulehnen.
                    </li>
                    <li>
                        Keine Mängelansprüche bestehen bei nur unerheblichen Abweichungen von der vereinbarten Beschaffenheit, bei nur unerheblicher Beeinträchtigung der Brauchbarkeit, bei natürlicher Abnützung oder bei Schäden, die nach Gefahrenübergang durch fehlerhafte, unsachgemäße oder nachlässige Behandlung/Verwendung oder ähnliche äußere Einflüsse entstanden sind. Eine mengenmäßige Abweichung von +/- 10% stellt keine erhebliche Abweichung dar. Ein natürlicher Gewichtsverlust gibt dem Kunden kein Recht Schaden- oder Mängelersatzansprüche zu stellen. Es gelten die Artikelbezeichnungen, welche von der Christoph Salomon GmbH zur Verfügung gestellt werden.
                    </li>
                    <li>
                        Liefer- und Leistungsverzögerungen sowie Kostenerhöhungen, die durch unrichtige, unvollständige oder nachträglich geänderte Angaben und Informationen oder zur Verfügung gestellte Unterlagen entstehen, werden von der Christoph Salomon GmbH nicht vertreten und können nicht zum Verzug von der Christoph Salomon GmbH führen. Daraus resultierende Mehrkosten trägt der Vertragspartner.
                    </li>
                    <li>
                        Im Falle der unberechtigten Geltendmachung von Gewährleistungsansprüchen ist die Christoph Salomon GmbH berechtigt, die angefallenen Kosten dem Vertragspartner in Rechnung zu stellen.
                    </li>
                </ol>


                <h3>
                    VII. Haftung
                </h3>
                <ol>
                    <li>
                        Die Haftung von der Christoph Salomon GmbH für Schäden, die auf leichter Fahrlässigkeit beruhen, wird ausdrücklich ausgeschlossen.
                    </li>
                    <li>
                        Ebenso wird eine Haftung für Schäden, die auf unsachgemäßer Handhabung, anormale Betriebsbedingungen, Transportschäden, mangelnde organisatorische Rahmenbedingungen und unvollständige Unterlagen zurückzuführen sind, ausgeschlossen.
                    </li>
                    <li>
                        Der Ersatz von Folgeschäden und Vermögensschäden, Zinsverlusten, Schäden aus Ansprüchen Dritter gegen die Christoph Salomon GmbH ist in jedem Fall, soweit gesetzlich zulässig, ausgeschlossen.
                    </li>
                    <li>
                        Schadenersatzansprüche verjähren jedenfalls ein Monat nach Erbringung der Lieferung oder Leistung. Sie sind mit der Höhe des Rechnungsbetrages der den Schadenersatz auslösenden Lieferung und Leistung begrenzt.
                    </li>
                    <li>
                        Im Falle einer Haftung für einfache Fahrlässigkeit ist die Ersatzpflicht von der Christoph Salomon GmbH für Sach- oder Personenschäden auf einen Betrag von EUR 5.000,00 je Schadenfall (entsprechend der derzeitigen Deckungssumme seiner Produkthaftpflichtversicherung oder Haftpflichtversicherung) beschränkt, auch wenn es sich um eine Verletzung vertragswesentlicher Pflichten handelt.
                    </li>
                    <li>
                        Die vorstehenden Haftungsausschlüsse und –Beschränkungen gelten in gleichem Umfang zugunsten der Organe, gesetzlichen Vertreter, Angestellten und sonstigen Erfüllungsgehilfen der Christoph Salomon GmbH.
                    </li>
                    <li>
                        Soweit die Christoph Salomon GmbH beratend tätig wird und diese Beratung nicht zu dem von ihm geschuldeten, vertraglich vereinbarten Leistungsumfang gehört, geschieht dies unentgeltlich und unter Ausschluss jeglicher Haftung.
                    </li>
                    <li>
                        Die Einschränkung dieses Punkt VII. gelten nicht für die Haftung von der Christoph Salomon GmbH wegen vorsätzlichen Verhaltens, für garantierte Beschaffenheitsmerkmale, wegen Verletzung des Lebens, des Körpers oder die Gesundheit oder nach dem Produkthaftungsgesetz.
                    </li>
                </ol>


                <h3>
                    VIII. Datenschutz, Adressenänderung und Urheberrecht
                </h3>
                <ol>
                    <li>
                        Im Zusammenhang mit der Geschäftsbeziehung zwischen Auftraggeber und der Christoph Salomon GmbH werden nachstehend angeführte Daten wie Titel, Name, Anschrift zum Zwecke einer Kundenevidenz, Zusendung von Informationsmaterial und für das Rechnungswesen über den Auftraggeber automationsunterstützt gespeichert und verarbeitet.
                    </li>
                    <li>
                        Die Übermittlung der angegebenen Daten erfolgt nur im Rahmen des Zahlungsverkehrs.
                    </li>
                    <li>
                        Jede andere Form der Übermittlung bedarf der gesonderten Zustimmung des Auftraggebers. Die persönlichen Daten des Auftraggebers werden nur, soweit gesetzlich zulässig ist, verwendet und weitergegeben.
                    </li>
                    <li>
                        Der Auftraggeber ist verpflichtet, uns Änderungen seiner Wohn- bzw. Geschäftsanschrift bekannt zu geben, solange das vertragsgegenständliche Rechtsgeschäft nicht beidseitig vollständig erfüllt ist, Wird diese Mitteilung unterlassen, so gelten Erklärungen auch dann als zugegangen, falls sie an die zuletzt bekannt gegebene Adresse gesendet werden.
                    </li>
                    <li>
                        Kataloge, Prospekte, Abbildungen, maschinelle Prozesse und dergleichen bleiben stets unser geistiges Eigentum; der Auftraggeber erhält daran keine wie immer gearteten Werknutzungs- oder Verwertungsrechte.
                    </li>
                </ol>


                <h3>
                    IX. Eigentumsvorbehalt
                </h3>
                <ol>
                    <li>
                        Die von der Christoph Salomon GmbH an den Auftraggeber gelieferten und/oder eingebrachten Waren incl. Verpackungsmaterial und Transporthilfsmittel, die im Eigentum von der Christoph Salomon GmbH stehen, bleiben bis zur vollständigen Bezahlung aller Forderungen aus den Geschäftsverbindungen mit dem Kunden im Eigentum von der Christoph Salomon GmbH.
                    </li>
                    <li>
                        Eine Verarbeitung, Bearbeitung oder Umbildung der gelieferten Waren wird in jedem Fall für die Christoph Salomon GmbH vorgenommen. Für die Christoph Salomon GmbH entstehen hieraus keine Verpflichtungen. Sofern die Vorbehaltsware mit anderen, der Christoph Salomon GmbH nicht gehörenden Sachen be- oder verarbeitet werden, erwirbt die Christoph Salomon GmbH das Eigentum an den neuen Sachen im Verhältnis des Wertes der Vorbehaltsware (Rechnungsbetrag incl. USt.) zu anderen verarbeiteten Sachen im Zeitpunkt der Verarbeitung. Im Falle der untrennbaren Verbindung der Vorbehaltsware mit anderen, der Christoph Salomon GmbH nicht gehörender Sachen, erwirbt die Christoph Salomon GmbH Miteigentum an der neuen Sache im Verhältnis des Wertes der Vorbehaltsware (Rechnungsbetrag incl. Ust.) zu den anderen vermischten Sachten im Zeitpunkt der Vermischung. Ist die Sache infolge Vermischung als Hauptsache anzusehen, sind sich der Auftraggeber und die Christoph Salomon GmbH einig, dass der Auftraggeber der Christoph Salomon GmbH anteilig Miteigentum überträgt an dieser Sache; diese Übertragung wird hiermit angenommen. So entstandenes Allein- oder Miteigentum verwahrt der Auftraggeber für die Christoph Salomon GmbH.
                    </li>
                    <li>
                        Verpfändungen oder Sicherungsübereignung der Ware von der Christoph Salomon GmbH, der daraus entstehenden Gegenstände oder Forderungen vor Bezahlung der Ware ist während des Bestehens des Eigentumsvorbehaltes ausgeschlossen und untersagt.
                    </li>
                    <li>
                        Der Käufer ist verpflichtet, der Christoph Salomon GmbH den Zugriff dritter Personen auf die unter Eigentumsvorbehalt gelieferte Ware sofort mitzuteilen, um ihm die Durchsetzung seiner Eigentumsrechte zu ermöglichen.
                    </li>
                    <li>
                        Der Auftraggeber trägt alle Kosten für Interventionen und aller Abwehrmaßnahmen, die die Christoph Salomon GmbH für erforderlich erachtet.
                    </li>
                    <li>
                        Gerät der Vertragspartner in Zahlungsverzug, so ist die Christoph Salomon GmbH ohne weitere Mahnung berechtigt, sein Eigentum in Besitz zu nehmen und auf Kosten des Vertragspartner abzutransportieren, ohne dass darin ein Rücktritt vom Kaufvertrag liegt. Die Rücknahme erfolgt lediglich zur Sicherung unserer Forderungen. Der Vertragspartner bleibt zur Erfüllung verpflichtet.
                    </li>
                </ol>


                <h3>
                    X. Transporthilfsmittel und Verpackung
                </h3>
                <ol>
                    <li>
                        Von der Christoph Salomon GmbH wird stets das Nettogewicht oder der Nettoinhalt berechnet.
                    </li>
                    <li>
                        Die Lieferung erfolgt entweder in Kartonverpackung, die dem Käufer verbleibt oder aber in Plastikpaletten, die Leihverpackung darstellen.
                    </li>
                    <li>
                        Bei Lieferung in Leihverpackung und dies gilt auch für Paletten jeglicher Art, ist die gelieferte Anzahl sofort bei Anlieferung gegen Leihverpackung und/oder Paletten in einwandfreiem und sauberem Zustand zu tauschen. Ist dies nicht möglich, hat der Auftraggeber dafür Sorge zu tragen, dass die Rücksendung dieser Leihverpackung franko längstens binnen einen Monats nach Lieferung durch die Christoph Salomon GmbH erfolgt. Nach Ablauf von einem Monat kann das Leergut von der Christoph Salomon GmbH in Rechnung gestellt werden oder eine angemessene Miete verrechnet werden. Die von der Christoph Salomon GmbH gelieferten Leihverpackungen dürfen nicht mit anderen Waren gefüllt oder anderweitig verwendet werden.
                    </li>
                    <li>
                        Bis zur vollständigen Bezahlung sämtlicher Pfandgelder besitzt und verwahrt der Auftraggeber die Mehrweg-Verpackungen oder Mehrweg-Transporthilfsmittel, die nicht im Eigentum von der Christoph Salomon GmbH stehen, für die Christoph Salomon GmbH. Die Christoph Salomon GmbH behält sich solange ausdrücklich den mittelbaren Besitz an diesen Gegenständen vor. Die Weitergabe dieser Gegenstände vor Bezahlung sämtlicher offenen Pfandgeldforderungen ist untersagt und stellt verbotene Eigenmacht dar.
                    </li>
                    <li>
                        Bei Abholung oder Anlieferung der Ware in Mehrweg-Verpackungen oder Mehrweg Transporthilfsmittel, die im Eigentum von der Christoph Salomon GmbH stehen, verpflichtet sich der Auftraggeber für einen Umtausch/Austausch des Pfandgutes in sauberem und einwandfreiem Zustand. Werden die Mehrweg-Verpackungen oder Mehrweg Transporthilfsmittel, die im Eigentum von der Christoph Salomon GmbH stehen, nicht oder nicht ordnungsgemäß zurückgestellt, so ist die Christoph Salomon GmbH berechtigt, Schadenersatz in Höhe des Wiederbeschaffungspreises zum Zeitpunkt der Wiederbeschaffung zu verlangen.
                    </li>
                </ol>


                <h3>
                    XI. Loyalität und Geheimhaltungspflicht
                </h3>
                <ol>
                    <li>
                        Die Vertragspartner verpflichten sich zur gegenseitigen Loyalität.
                    </li>
                    <li>
                        Sie verpflichten sich weiteres Kenntnisse, gleich welcher Art, über den Vertragspartner geheim zu halten und weder Daten noch Unterlagen, gleich welcher Art, an unbefugte Dritte weiter zu geben.
                    </li>
                    <li>
                        Diese Verpflichtung ist auch an Dritte, die bei Erfüllung der wechselseitigen Leistungen beigezogen werden, zu überbinden.
                    </li>
                </ol>


                <h3>
                    XII. Gerichtsstand und Erfüllungsort
                </h3>
                <ol>
                    <li>
                        Es gilt ausschließlich österreichisches Recht als vereinbart.
                    </li>
                    <li>
                        die Anwendbarkeit des UN-Kaufrechtes wird ausdrücklich ausgeschlossen.
                    </li>
                    <li>
                        Erfüllungsort und Gerichtsstand für sämtliche Verpflichtungen beider Teile ist Wien.
                    </li>
                    <li>
                        Für Streitigkeiten aus diesen Verkaufsbedingungen unterwerfen sich die Streitteile dem sachlich zuständigen Gericht in Wien.
                    </li>
                </ol>


                <h3>
                    XIII. Sonstiges
                </h3>
                <ol>
                    <li>
                        Mündliche Vereinbarungen sind wirkungslos.
                    </li>
                    <li>
                        Abänderungen zu den oben genannten Verkaufsbedingungen bedürfen der schriftlichen Genehmigung von der Christoph Salomon GmbH.
                    </li>
                    <li>
                        Persönlich erteilte Aufträge können nicht storniert werden.
                    </li>
                    <li>
                        Sollten einzelne Bestimmungen dieser Allgemeinen Verkaufs- und Lieferbedingungen bzw. des abgeschlossenen Auftrages rechtsunwirksam sein oder während der Geltungsdauer des Vertrages aufgrund gesetzlicher Bestimmungen oder behördlicher Entscheidungen rechtsunwirksam werden, so berührt dies die Gültigkeit der verbleibenden Bestimmungen nicht. Anstelle der unwirksamen Bestimmung gilt dasjenige zwischen den Vertragsparteien als vereinbart, das aus wirtschaftlicher Sicht gesehen dieser unwirksamen Bestimmung in rechtlich zulässiger Weise am ehesten entspricht.
                    </li>
                </ol>
                <p>
                    Stand Mai 2024
                </p>

            </div>

        </section>
    );
};

export default AGB;
