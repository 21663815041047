import React from 'react';
import '../styles/Hero2.css';
import bg2 from '../assets/bg2.png'
import bgOLD from '../assets/bgOLD.jpg'
import { Link } from 'react-scroll';


const Hero2_1 = () => {
    return (
        <section className="hero2_1" id='section2_1'>
            {/* <div className='bg2'> */}
                {/* <div className='color'></div> */}
                <div className='text'>
                    <h1>Das Marchfeld,</h1>
                    <h3 className='p1_1'>Österreichs größter
                        <br />
                        Gemüsegarten
                    </h3>
                    <p>
                        Das Gemüse aus dem Marchfeld ist für seine Frische und Qualität bekannt – nicht nur in Österreich,
                        sondern international. Die regionalen Bauern setzen auf nachhaltigen Anbau und bewahren das
                        natürliche Gleichgewicht der Region. Diese Nähe zur Natur und das handwerkliche Geschick in der
                        Landwirtschaft machen das Marchfeld zur idealen Quelle für Produkte, die regional, frisch und
                        unverfälscht im Geschmack sind.
                    </p>

                    <p>
                        Eben auch wie unser „DOCH! allrounder“.
                    </p>


                </div>
               
            {/* </div> */}

        </section>
    );
};

export default Hero2_1;
