import React from 'react';
import '../styles/Impressum.css';
import { Link } from 'react-scroll';




const Impressum = () => {
    return (
        <section className="impressum" id='impressum'>
            <div className="impressum-content">
                <h1>Impressum</h1>
                <p>
                    Christoph Salomon GmbH
                    <br />
                    Spöttlgasse 7/3/13
                    <br />
                    A – 1210 Wien
<br />
<br />
                    Produktion und Handel mit Gewürzen
<br />
<br />
                    UID-Nummer: ATU80300425
<br />
<br />
                    FN 619067 v
                    <br />
                    Handelsgericht Wien
<br /><br />
                    Tel.Nr.: +43 664 9250881
                </p>
            </div>

        </section>
    );
};

export default Impressum;
