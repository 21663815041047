import React from 'react';
import '../styles/Hero3.css';
import { Link } from 'react-scroll';
import Footer from './Footer';




const Hero3_3 = () => {
    return (
        <>
            <section className="hero3_3" id='section3_3'>
                <div className="hero3_3-content">
                    <div className='firstBlock'>
                        <div className='buyText'>
                            <div>
                                <h1>Online</h1>
                                <p>kaufen</p>
                            </div>

                            <p className='orangeP'>
                                Hol dir den Geschmack des Marchfelds direkt nach Hause! Bestelle den DOCH! allrounder bequem online – schnell, unkompliziert und direkt zu dir geliefert. Egal ob für deine Alltagsküche oder als Geschenk für Feinschmecker: Mit nur wenigen Klicks steht dir die volle Würzpower zur Verfügung.
                            </p>

                            <div className='buyLinkContainer'>
                                <a className='buyLink' href="https://myproduct.at/brand/doch" target='blank'>myproduct.at</a>
                                <a className='buyLink' href="http://shöpping.at" target='blank'>shöpping.at</a>
                            </div>
                        </div>


                    </div>
                    <div className='secondBlock'>
                        <div className='buyText handel'>
                            <div>
                                <h1 className='buySecondTitle'>Im Handel</h1>
                                <p>kaufen</p>
                            </div>


                            <p className='orangeP secondText'>
                                Möchtest du DOCH! vor Ort entdecken? Besuche unsere Partnergeschäfte und nimm den DOCH! allrounder gleich mit. Lass dich inspirieren und erlebe unser Produkt hautnah – für noch mehr Geschmack und Genuss in deiner Küche.
                            </p>

                            <div className='secondBlockText'>
                                <p className='buyAddress'>
                                    <b>`s Fachl Wien</b><br />
                                    Alser Straße 43<br />
                                    1080 Wien
                                </p>
                                {/* <p className='buyAddress'>
                                <b>Mustergeschäft</b><br />
                                Musterstraße<br />
                                Musterstadt
                            </p> */}
                            </div>
                        </div>



                    </div>
                </div>




            </section>
            {/* <Footer /> */}
        </>


    );
};

export default Hero3_3;
